import Vue from 'vue';

import '@nedap/unrux/dist/unrux.css';
import '@nedap/unrux/src/lib-scss/colors.scss';
import '@nedap/unrux/src/lib-scss/fonts/fonts.scss';
import { humanizedEnvironment } from '@nedap/unrux/src/lib-helpers/server_environments.js';

import { EnvironmentIndicator, Footer } from '@nedap/unrux';

/**
 * Load translations early, at least before jQuery/DataTables is loaded
 *  Translations are now only added to the `session.js` pack to enable debugging in a docker container (that only loads the login form)
 */
import './translations.js';

import 'components/session/session.scss';
import 'components/roadsign/roadsign';

import { InlineSvgPlugin } from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);

window.vueApp = new Vue({
  el: '#app',
  filters: {
    humanizedEnvironment,
  },
  components: {
    EnvironmentIndicator,
    'x-footer': Footer,
  },
});
